<template>
    <div class="group" :class="themeClass">
        <div class="group__header">
            <div class="group__header__info">
                <h3 class="group-name">{{ group.group_name }}</h3>
            </div>
            <div class="group__header__affected">
                <Switcher
                    :aria-label="affected ? $t('history.list.group.aria-affected-only') : $t('history.list.group.aria-all-services')"
                    v-if="!filter"
                    v-model="affected"
                    :label="$t('history.list.group.affected-only')"
                />
            </div>
            <div v-if="showNavigation"
                class="group__header__navigation"
            >
                <Button
                    @click="next"
                    icon="chevron-left"
                    class="button-next"
                    :disabled="cannotNext"
                >
                    {{ $t('history.list.group.next') }}
                </Button>
                <Button
                    @click="previous"
                    icon="chevron-right"
                    class="button-prev"
                    :content-reverse="true"
                    :disabled="cannotPrev"
                >
                    {{ $t('history.list.group.prev') }}
                </Button>
            </div>
        </div>
        <table class="group__services">
            <thead>
                <tr class="group__services__headers">
                    <th class="header">
                        <span class="service-name-label">{{ $t('history.list.group.services.label') }}</span>
                    </th>
                    <th class="label" v-for="(label, index) in pluralizedLabels" :key="index" scope="col">
                        <span class="label__text label__text--two-lines" v-html="label.replace(' ', '<br />')"></span>
                        <span class="label__text">{{ label }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <Service
                    v-for="(service, index) in services"
                    :key="service.service_id"
                    :id="`service-${service.service_id}`"
                    :service="service"
                    :labels="labels"
                />
            </tbody>
        </table>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Switcher from '@/components/Switch.vue'
import Button from '@/components/Button.vue'
import Service from './group/Service.vue'
import progress from '@/mixins/progress';
import theme from '@/mixins/theme';

export default {
    mixins: [progress, theme],
    components: {
        Switcher,
        Button,
        Service
    },
    props: {
        group: {
            type: Object,
            required: true
        },
        labels: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            to: new Date(),
            affected: true
        }
    },
    watch: {
        refreshing(refreshing) {
            if (refreshing) {
                this.to = new Date()
            }
        },
        config(config) {
            if (config) {
                this.affected = !(config.all_statuses_default)
            }
        }
    },
    computed: {
        ...mapGetters({
            filter: 'history/filter',
            refreshing: 'refreshing',
            config: 'config',
            offline: 'offlineAttr'
        }),
        services() {
            return !this.affected || !!this.filter ?
                this.group.services :
                this.group.services.filter(service =>
                    service.days.find(
                        day => day.status !== 1 || day.incident_ids.length || day.maintenance_ids.length
                    )
                )
        },
        cannotNext() {
            const next = new Date(this.to);
            next.setDate(next.getDate() + 1);

            return this.loading || (new Date().getTime() < next.getTime()) || this.offline;
        },
        cannotPrev() {
            return this.loading || this.offline
        },
        from() {
            const from = new Date(this.to);
            from.setDate(from.getDate() - 6);

            return from;
        },
        pluralizedLabels() {
            const pr = new Intl.PluralRules(this.$i18n.locale, { type: 'ordinal' });

            return Object.values(this.labels).map(date => {
                if (date === 'Today') {
                    return this.$t('history.list.group.labels.Today')
                }

                const [ month, day ] = date.split('-').slice(1, 3)
                return `${this.$t(`history.list.group.labels.${parseInt(month) - 1}`)} ${parseInt(day)}${this.$t(`history.calendar.ordinals.${pr.select(parseInt(day))}`)}`
            })
        },
        showNavigation() {
            return this.config.no_previous_history !== true
        }
    },
    methods: {
        ...mapActions({
            fetch: 'history/fetchGroupHistory'
        }),
        next() {
            this.to.setDate(this.to.getDate() + 7);
            this.to = new Date(this.to);

            return this.fetchHistory();
        },
        previous() {
            this.to.setDate(this.to.getDate() - 7);
            this.to = new Date(this.to);

            return this.fetchHistory();
        },
        fetchHistory() {
            return this.progress(() => this.fetch({
                groupId: this.group.group_id,
                from: this.from,
                to: this.to
            }));
        }
    },
    mounted() {
        this.affected = !(this.config.all_statuses_default)
    }
}
</script>
<style lang="scss" scoped>
.group {
    margin-bottom: 32px;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;

        @include breakpoint($md) {
            flex-direction: row;
            padding-top: 16px;
            padding-bottom: 16px;
        }

        &__info {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-right: 8px;

            @include breakpoint($md) {
                text-align: left;
                flex: 1;
            }

            .group-name {
                font-size: 1.125rem;
                font-weight: 500;
                white-space: pre-wrap;
                margin-block-start: 0em;
                margin-block-end: 0em;
            }
        }

        &__affected {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-right: 8px;
        }

        &__navigation {
            display: flex;
            padding-top: 8px;
            padding-bottom: 8px;

            .button-next {
                @include button-rounded();

                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding-left: 48px;
                padding-right: 16px;
            }

            .button-prev {
                @include button-rounded();

                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                padding-left: 16px;
                padding-right: 48px;
            }
        }
    }

    &__services {
        padding-left: 0px;
        border: 2px solid #F9FAFC;
        border-spacing: 0;
        width: 100%;

        &__headers {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 8px;
            background-color: #F9FAFC;

            @include breakpoint($md) {
                grid-template-columns: 250px repeat(7, 1fr);
                padding-left: 16px;
            }

            .header {
                width: 100%;
                text-align: center;
                padding: 0;
                align-self: center;
                justify-self: center;
                grid-column-start: 1;
                grid-column-end: 8;

                @include breakpoint($md) {
                    grid-column-end: 2;
                    text-align: left;
                    width: 250px;
                }

                .service-name-label {
                    color: $textColor2;
                    text-transform: uppercase;
                    font-size: 0.6875rem;
                    font-weight: 600;
                    letter-spacing: 0.46px;
                    text-align: center;
                }
            }

            .label {
                text-align: center;
                color: $textColor;
                font-size: 0.6875rem;
                letter-spacing: 0.46px;
                font-weight: 600;
                text-transform: uppercase;
                justify-self: center;
                align-self: center;

                &__text {
                    display: none;

                    @include breakpoint($md) {
                        display: block;
                    }

                    &--two-lines {
                        display: block;

                        @include breakpoint($md) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.theme--compact {
    &.group {
        &__services {
            border: none;

            .service-name-label {
                font-weight: 700;
            }

            .label {
                font-weight: 700;
            }
        }
    }
}
</style>
